export default class TimeZone {
	constructor(dto) {
		this.ianaZoneId = '';
		this.displayName = '';
		this.sortOrder = 0;

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.ianaZoneId === 'string') {
				this.ianaZoneId = dto.ianaZoneId;
			}
			if (typeof dto.displayName === 'string') {
				this.displayName = dto.displayName;
			}
			if (typeof dto.sortOrder === 'number') {
				this.sortOrder = dto.sortOrder;
			}
		}
	}
}
