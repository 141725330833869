import { url as urlHelper } from '@/helpers/helpers';
import PaginatedList from '@/models/PaginatedList';
import UserAbsence from '@/models/UserAbsence';
import { DateTime } from 'luxon';
import { fetchAllPages, fetchWrap, offlineResponse, setPagination } from '../_helpers';

export default {
	/**
	 * Get all user absences
	 * @param {Object} params request parameters.
	 * @returns (async) Returns an array of UserAbsence objects if the request was successful, otherwise a Response.
	 */
	async getAll({ userId = undefined, startDate = undefined, endDate = undefined } = {}) {
		const query = {};
		if (typeof userId === 'number') {
			query.userId = userId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/UserAbsence', query);
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(new UserAbsence(x)));
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Get paginated user absences
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of UserAbsence objects if the request was successful, otherwise a Response.
	 */
	async getPaginated({ userId = undefined, startDate = undefined, endDate = undefined, limit = undefined, start = undefined } = {}) {
		const query = setPagination(limit, start);
		if (typeof userId === 'number') {
			query.userId = userId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/UserAbsence', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new UserAbsence(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a user absence
	 * @param {Number} id UserAbsence ID
	 * @returns (async) Returns a UserAbsence if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response;
		try {
			response = await fetchWrap('/api/UserAbsence/' + id);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new UserAbsence(await response.json());
		} else {
			throw response;
		}
	},
	/**
	 * Create a user absence
	 * @param {UserAbsence} model user absence to create.
	 * @returns (async) Returns the new UserAbsence if the request was successful, otherwise a Response.
	 */
	async create(model) {
		let response;
		try {
			response = await fetchWrap('/api/UserAbsence', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new UserAbsence(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Update a user absence
	 * @param {UserAbsence} model user absence to update.
	 * @returns (async) Returns the updated UserAbsence if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/UserAbsence/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new UserAbsence(model);
		} else {
			return response;
		}
	},
	/**
	 * Delete a user absence
	 * @param {Number} id UserAbsence ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the user absence could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/UserAbsence/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok || response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
