export default class Permission {
	static notSet = 0;
	// admin
	static adminAccessAll = 32767;
	static admin = 1;
	static adminUsers = 2;
	static adminRoles = 3;
	static adminHangfire = 4;
	static adminImpersonate = 70;
	// company
	static companyAccessAll = 32766;
	static companyOperator = 9;
	static companyOffice = 10;
	static companySettings = 12;
	static companyUsers = 11;
	static companyTimeSheets = 13;
	static companyReports = 14;
	static companyBypassIpRestrictions = 15;
	static companyRentals = 16;
}
