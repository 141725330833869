import { getValueFromDto as getValue } from './_helpers.js';
import Permission from './Permission.js';

export default class PermissionDisplay {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.key = getValue(dto, 'key', 'string', null);
		this.group = getValue(dto, 'group', 'string', null);
		this.name = getValue(dto, 'name', 'string', null);
		this.description = getValue(dto, 'description', 'string', null);
		this.sortOrder = getValue(dto, 'sortOrder', 'number', 0);
		this.module = getValue(dto, 'module', 'number', null);
	}

	toJSON() {
		return this.id;
	}

	static isValid(value) {
		return Object.values(Permission).includes(value);
	}
}
