import SubscriptionBillingFrequency from './SubscriptionBillingFrequency.js';
import SubscriptionModule from './SubscriptionModule.js';
import SubscriptionUserTier from './SubscriptionUserTier.js';
import { getArrayOfObjectsFromDto as getArray, getValueFromDto as getValue } from './_helpers.js';

export default class Subscription {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.name = getValue(dto, 'name', 'string', '');
		this.active = getValue(dto, 'active', 'boolean', true);
		this.billingFrequency = getValue(dto, 'billingFrequency', 'number', SubscriptionBillingFrequency.month);
		this.userTiers = getArray(dto, 'userTiers', SubscriptionUserTier);
		this.modules = getArray(dto, 'modules', SubscriptionModule);
		this.minBilledUsers = getValue(dto, 'minBilledUsers', 'number', 0);
	}
}
