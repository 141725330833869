import { url as urlHelper } from '@/helpers/helpers';
import Email from '@/models/Email';
import PaginatedList from '@/models/PaginatedList';
import { fetchWrap, isAborted, offlineResponse, setPagination } from '../_helpers';

export default {
	/**
	 * Get paginated emails
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of Email objects if the request was successful, otherwise a Response.
	 */
	async getPaginated({ customerId = undefined, workOrderId = undefined, limit = undefined, start = undefined } = {}, abortSignal) {
		const path = workOrderId > 0 ? '/api/Emails/WorkOrder/' + workOrderId : (customerId > 0 ? '/api/Emails/CustomerStatements/' + customerId : '/api/Emails');
		const url = urlHelper(path, setPagination(limit, start));
		let response, data;
		try {
			const init = {};
			if (abortSignal instanceof AbortSignal) {
				init.signal = abortSignal;
			}
			response = await fetchWrap(url, init);
			if (response.ok) { data = await response.json(); }
		} catch (e) {
			if (isAborted(e)) {
				throw e;
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			return new PaginatedList(data, x => new Email(x));
		} else {
			throw response;
		}
	},
	/**
	 * Unsuppress an email address that has bounced or been blocked.
	 * @param {string} address The email address
	 * @returns (async) Returns the Response.
	 */
	async unsuppressAddress(address) {
		let response;
		try {
			response = await fetchWrap(urlHelper('/api/Emails/UnsuppressAddress', { address }), { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		return response;
	},
};
