export default {
	never: 0,
	month: 1,

	get options() {
		return [
			{ id: this.never, name: 'Never' },
			{ id: this.month, name: 'Month' },
		]
	},
}
