import { url as urlHelper } from '@/helpers/helpers';
import CustomerRental from '@/models/CustomerRental';
import { CustomerRentalDetailed } from '@/models/CustomerRentalDetailed';
import LocationForMap from '@/models/LocationForMap';
import PaginatedList from '@/models/PaginatedList';
import RentalItemSchedule from '@/models/RentalItemSchedule';
import { DateTime } from 'luxon';
import { fetchAllPages, fetchWrap, isAborted, offlineResponse, setPagination } from '../_helpers';

export default {
	/**
	 * Get paginated work orders for the specified customer (and optionally location) in the specified date range.
	 * @param {Object} params request parameters.
	 * @returns {Promise<PaginatedList<WorkOrder>>} (async) Returns a PaginatedList of WorkOrder objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getForCustomer({ customerId, customerLocationId = undefined, startDate = undefined, endDate = undefined, limit = undefined, start = undefined, sort = undefined } = {}) {
		const query = setPagination(limit, start);
		if (typeof customerId === 'number') {
			query.customerId = customerId;
		}
		if (typeof customerLocationId === 'number') {
			query.customerLocationId = customerLocationId;
		}

		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}

		if (typeof sort === 'string') {
			query.sort = sort;
		}
		const url = urlHelper('/api/CustomerRentals/ForCustomer', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new CustomerRental(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get customre rentals that need attention, paginated.
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of CustomerRental objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getForAttention({ limit = undefined, start = undefined } = {}) {
		const url = urlHelper('/api/CustomerRentals/ForAttention', setPagination(limit, start));
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new CustomerRental(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get rental item schedules for the specified rental item in the specified date range.
	 * @returns {Promise<PaginatedList>} (async) Returns a paginated list of RentalItemSchedule objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getRentalItemHistory({ rentalItemId, startDate = undefined, endDate = undefined, limit = undefined, start = undefined, sort = undefined } = {}) {
		const query = setPagination(limit, start);
		if (typeof rentalItemId === 'number') {
			query.rentalItemId = rentalItemId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		if (typeof sort === 'string') {
			query.sort = sort;
		}
		const url = urlHelper('/api/CustomerRentals/ForItem', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch (e) {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new RentalItemSchedule(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get all customer rentals scheduled in the specified date range.
	 * @param {Object} params request parameters.
	 * @returns {Promise<CustomerRental[]>} (async) Returns an array of customerRental objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getAllForDispatch({ startDate = undefined, endDate = undefined } = {}) {
		const query = {};
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/CustomerRentals/ForDispatch', query);
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(new CustomerRental(x)));
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return data;
		} else {
			throw response;
		}
	},
	/**
	* Get a list of data for single customer location
	* @param {Object} params request parameters.
	* @returns {Promise<CustomerRentalDetailed[]>} (async) Returns an array of objects if the request was successful.
	* @throws {Response} Will throw the response if the request was not successful.
	*/
	async getForMapDetails({ customerLocationId, startDate, endDate }, abortSignal) {
		const query = {};
		if (typeof customerLocationId === 'number') {
			query.customerLocationId = customerLocationId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/CustomerRentals/ForMapDetails', query);
		let response;
		try {
			const init = {};
			if (abortSignal instanceof AbortSignal) {
				init.signal = abortSignal;
			}
			response = await fetchWrap(url, init);
		} catch (e) {
			if (isAborted(e)) {
				throw e;
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			return (await response.json()).map(x => new CustomerRentalDetailed(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a list of inital location data for google map
	 * @param {Object} params request parameters.
	 * @returns {Promise<LocationForMap[]>} (async) Returns an array of objects if the request was successful.
	 * @throws {Response} Will throw the response if the request was not successful.
	 */
	async getForMapBasic({ startDate = undefined, endDate = undefined } = {}, abortSignal) {
		const query = {};
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/CustomerRentals/ForMapBasic', query);
		let response;
		try {
			const init = {};
			if (abortSignal instanceof AbortSignal) {
				init.signal = abortSignal;
			}
			response = await fetchWrap(url, init);
		} catch (e) {
			if (isAborted(e)) {
				throw e;
			} else {
				response = offlineResponse();
			}
		}
		if (response.ok) {
			return (await response.json()).map(x => new LocationForMap(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a customer rental
	 * @param {Number} id customer rental ID
	 * @returns (async) Returns a customer rental if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response;
		try {
			response = await fetchWrap('/api/CustomerRentals/' + id);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new CustomerRental(await response.json());
		} else {
			throw response;
		}
	},
};
