import { getValueFromDto as getValue, getDateTimeFromDto as getDate } from './_helpers.js';

export default class VehicleMaintenance {
	constructor(dto, maintenanceTypes) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.vehicleId = getValue(dto, 'vehicleId', 'number', null);
		this.maintenanceTypeId = getValue(dto, 'maintenanceTypeId', 'number', null);
		this.maintenanceType = null;
		Object.defineProperty(this, 'maintenanceType', { enumerable: false });
		this.timestamp = getDate(dto, 'timestamp', null);
		this.mileage = getValue(dto, 'mileage', 'number', 0);
		this.hours = getValue(dto, 'hours', 'number', 0);
		this.notes = getValue(dto, 'notes', 'string', '');

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.maintenanceType === 'object' && dto.maintenanceType !== null) {
				this.maintenanceType = dto.maintenanceType;
			} else if (typeof maintenanceTypes === 'object' && Array.isArray(maintenanceTypes)) {
				this.maintenanceType = maintenanceTypes.find(x => x.id === this.maintenanceTypeId) || null;
			}
		}
	}
}
