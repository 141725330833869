import { getValueFromDto as getValue, getDateTimeFromDto as getDate } from './_helpers.js';

export default class UserAbsence {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.userId = getValue(dto, 'userId', 'number', null);
		this.startOfAbsence = getDate(dto, 'startOfAbsence', null);
		this.endOfAbsence = getDate(dto, 'endOfAbsence', null);
	}
}
