import WorkOrderStatus from './WorkOrderStatus.js';
import { getValueFromDto as getValue } from './_helpers.js';

export default class WorkOrderReference {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.number = getValue(dto, 'number', 'number', null);
		this.status = getValue(dto, 'status', 'number', WorkOrderStatus.estimate);
	}
}
