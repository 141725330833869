import { getValueFromDto as getValue, getArrayFromDto as getArray } from './_helpers.js';

export default class Role {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.name = getValue(dto, 'name', 'string', '');
		this.description = getValue(dto, 'description', 'string', '');
		this.permissionsInRole = getArray(dto, 'permissionsInRole', []);
	}
}
