import { url as urlHelper } from '@/helpers/helpers';
import Subscription from '@/models/Subscription';
import SubscriptionDetails from '@/models/SubscriptionDetails';
import SubscriptionDetailsReport from '@/models/SubscriptionDetailsReport';
import { DateTime } from 'luxon';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
	/**
	 * Get all subscriptions
	 * @param {Object} params request parameters.
	 * @param {Boolean} params.active if specified, only return either active or inactive subscriptions.
	 * @returns {Promise<Subscription[]>} (async) Returns an array of Subscriptions if the request was successful, otherwise a Response.
	 */
	async getAll({ active = undefined } = {}) {
		const query = {};
		if (typeof active === 'boolean') {
			query.active = active;
		}
		const url = urlHelper('/api/Subscriptions', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return (await response.json()).map(x => new Subscription(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a subscription
	 * @param {Number} id Subscription ID
	 * @returns (async) Returns a Subscription if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response;
		try {
			response = await fetchWrap('/api/Subscriptions/' + id);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new Subscription(await response.json());
		} else {
			throw response;
		}
	},
	/**
	 * Get current company subscription
	 * @returns (async) Returns a SubscriptionDetails if the request was successful, otherwise a Response.
	 */
	async getCurrent() {
		let response;
		try {
			response = await fetchWrap('/api/Subscriptions/Current');
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new SubscriptionDetails(await response.json());
		} else {
			throw response;
		}
	},
	/**
	 * Get company subscription report for all active companies
	 * @param {DateTime} month Report month. Must be the first day of the month.
	 * @returns (async) Returns a SubscriptionDetailsReport if the request was successful, otherwise a Response.
	 */
	async getReport(month) {
		let response;
		try {
			response = await fetchWrap(urlHelper('/api/Subscriptions/Report', { month: month.toISODate() }));
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return (await response.json()).map(x => new SubscriptionDetailsReport(x));
		} else {
			throw response;
		}
	},
	/**
	 * Create a subscription
	 * @param {Subscription} model subscription to create.
	 * @returns (async) Returns the new Subscription if the request was successful, otherwise a Response.
	 */
	async create(model) {
		let response;
		try {
			response = await fetchWrap('/api/Subscriptions', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new Subscription(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Update a subscription
	 * @param {Subscription} model subscription to update.
	 * @returns (async) Returns the updated Subscription if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/Subscriptions/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new Subscription(model);
		} else {
			return response;
		}
	},
	/**
	 * Delete a subscription
	 * @param {Number} id Subscription ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the subscription could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/Subscriptions/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok || response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
