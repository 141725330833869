import { getArrayFromDto as getArray, getValueFromDto as getValue } from './_helpers.js';

export default class Company {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.name = getValue(dto, 'name', 'string', '');
		this.active = getValue(dto, 'active', 'boolean', true);
		this.subscriptionId = getValue(dto, 'subscriptionId', 'number', 0);
		this.modules = getArray(dto, 'modules', []);
		Object.defineProperty(this, 'subscription', { enumerable: false, value: null, writable: true });
	}
}
