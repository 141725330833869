import { url as urlHelper } from '@/helpers/helpers';
import PaginatedList from '@/models/PaginatedList';
import VehicleMaintenance from '@/models/VehicleMaintenance';
import { DateTime } from 'luxon';
import { fetchAllPages, fetchWrap, offlineResponse, setPagination } from '../_helpers';

export default {
	/**
	 * Get all vehicle maintenance records
	 * @param {Object} params request parameters.
	 * @returns (async) Returns an array of VehicleMaintenance objects if the request was successful, otherwise a Response.
	 */
	async getAll({ vehicleId = undefined, startDate = undefined, endDate = undefined } = {}, maintenanceTypes) {
		const query = {};
		if (typeof vehicleId === 'number') {
			query.vehicleId = vehicleId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/VehicleMaintenance', query);
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(new VehicleMaintenance(x, maintenanceTypes)));
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Get paginated vehicle maintenance records
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of VehicleMaintenance objects if the request was successful, otherwise a Response.
	 */
	async getPaginated({ vehicleId = undefined, startDate = undefined, endDate = undefined, limit = undefined, start = undefined } = {}, maintenanceTypes) {
		const query = setPagination(limit, start);
		if (typeof vehicleId === 'number') {
			query.vehicleId = vehicleId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/VehicleMaintenance', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new VehicleMaintenance(x, maintenanceTypes));
		} else {
			throw response;
		}
	},
	/**
	 * Get a vehicle maintenance
	 * @param {Number} id VehicleMaintenance ID
	 * @returns (async) Returns a VehicleMaintenance if the request was successful, otherwise a Response.
	 */
	async getById(id, maintenanceTypes) {
		let response;
		try {
			response = await fetchWrap('/api/VehicleMaintenance/' + id);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new VehicleMaintenance(await response.json(), maintenanceTypes);
		} else {
			throw response;
		}
	},
	/**
	 * Create a vehicle maintenance
	 * @param {VehicleMaintenance} model vehicle maintenance to create.
	 * @returns (async) Returns the new VehicleMaintenance if the request was successful, otherwise a Response.
	 */
	async create(model, maintenanceTypes) {
		let response;
		try {
			response = await fetchWrap('/api/VehicleMaintenance', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new VehicleMaintenance(await response.json(), maintenanceTypes);
		} else {
			return response;
		}
	},
	/**
	 * Update a vehicle maintenance
	 * @param {VehicleMaintenance} model vehicle maintenance to update.
	 * @returns (async) Returns the updated VehicleMaintenance if the request was successful, otherwise a Response.
	 */
	async update(model, maintenanceTypes) {
		let response;
		try {
			response = await fetchWrap('/api/VehicleMaintenance/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new VehicleMaintenance(model, maintenanceTypes);
		} else {
			return response;
		}
	},
	/**
	 * Delete a vehicle maintenance
	 * @param {Number} id VehicleMaintenance ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the vehicle maintenance could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/VehicleMaintenance/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok || response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
