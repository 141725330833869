import { getIdb, idbHelpers } from '@/idb';
import { fetchWrap, idbResponse, offlineResponse } from '../_helpers';
import files from './files';

export default {
	/**
	 * Update IDB work orders, customers, and waste disposals
	 */
	async download() {
		const idb = await getIdb();
		if (!idb) return idbResponse(500);
		let response, data;
		try {
			response = await fetchWrap('/api/sync-download');
			if (response.ok) { data = await response.json(); }
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			await idbHelpers.replaceAll(idb, 'workOrders', data.workOrders);
			await idbHelpers.replaceAll(idb, 'customers', data.customers);
			await idbHelpers.replaceAll(idb, 'wasteDisposals', data.wasteDisposals);
			// data.vehicleIds
			const fileMap = {};
			data.workOrders.forEach(x => x.attachments.forEach(y => fileMap[y.id] = y));
			data.customers.forEach(x => x.locations.forEach(y => y.attachments.forEach(z => fileMap[z.id] = z)));
			files.storeInIdb(Object.values(fileMap), true);
		}
		return response;
	},
};
