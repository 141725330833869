import { broadcast } from '@/helpers/broadcast';
import { clearDatabase } from '@/idb';
import UserSelf from '@/models/UserSelf';
import { API_CACHE_NAME, addCompanyIdToUrl, fetchWrap, offlineResponse } from '../_helpers';

export default {
	async getSelf() {
		let response, data;
		const request = new Request(addCompanyIdToUrl('/api/users/self'));
		try {
			response = await fetch(request);
			if (response.ok) {
				data = await response.json();
				if (!(data.id > 0) || !(data.companyId > 0)) {
					await clearDatabase();
				}
			}
		} catch {
			// directly check the cache so this API call works when called from the service worker while offline
			try {
				const cache = await caches.open(API_CACHE_NAME);
				response = await cache.match(request, { ignoreMethod: true });
				if (!response) { response = offlineResponse(); }
				if (response.ok) { data = await response.json(); }
			} catch (e) {
				console.error(e);
				response = offlineResponse();
			}
		}
		return data ? new UserSelf(data) : null;
	},
	async login(model) {
		await clearDatabase();
		let response;
		try {
			response = await fetchWrap('/api/Users/login', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async loginWith2faCode(model) {
		await clearDatabase();
		let response;
		try {
			response = await fetchWrap('/api/Users/LoginWith2faCode', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async loginWithRecoveryCode(model) {
		await clearDatabase();
		let response;
		try {
			response = await fetchWrap('/api/Users/LoginWithRecoveryCode', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async logout() {
		let response;
		try {
			response = await fetchWrap('/api/Users/logout', { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			await clearDatabase();
			broadcast.postMessage({ action: 'reload', });
		}
		return response;
	},
	async requestPasswordReset(model) {
		let response;
		try {
			response = await fetchWrap('/api/Users/forgotpassword', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async resetPassword(model) {
		let response;
		try {
			response = await fetchWrap('/api/Users/resetpassword', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async updateSelf(model) {
		let response;
		try {
			response = await fetchWrap('/api/users/self', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async changePassword(model) {
		let response;
		try {
			response = await fetchWrap('/api/Users/changepassword', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async confirmEmailChange(userId, email, code) {
		let response;
		try {
			response = await fetchWrap('/api/Users/ConfirmEmailChange', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ userId, email, code }),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async setup(model) {
		let response;
		try {
			response = await fetchWrap('/api/Users/setup', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async get2faInfo() {
		let response;
		try {
			response = await fetchWrap('/api/Users/Get2faInfo');
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return await response.json();
		}
		return response;
	},
	async previewEnable2fa() {
		let response;
		try {
			response = await fetchWrap('/api/Users/PreviewEnable2fa', { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return await response.json();
		}
		return response;
	},
	async enable2fa(code) {
		let response;
		try {
			response = await fetchWrap('/api/Users/Enable2fa', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ code }),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return await response.json();
		}
		return response;
	},
	async generate2faRecoverCodes() {
		let response;
		try {
			response = await fetchWrap('/api/Users/Generate2faRecoverCodes', { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return await response.json();
		}
		return response;
	},
	async reset2fa() {
		let response;
		try {
			response = await fetchWrap('/api/Users/Reset2fa', { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async disable2fa() {
		let response;
		try {
			response = await fetchWrap('/api/Users/Disable2fa', { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	async forgetThisMachine2fa() {
		let response;
		try {
			response = await fetchWrap('/api/Users/ForgetThisMachine2fa', { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		return response;
	},
};
