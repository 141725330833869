import { fetchWrap, offlineResponse } from '../_helpers';

export default {
	/**
	 * Start re-indexing customers for search for all companies. Happens in the background with Hangfire.
	 */
	async reIndexCustomerSearchAll() {
		try {
			return await fetchWrap('/api/Maintenance/ReIndexCustomerSearch', { method: 'POST' });
		} catch {
			return offlineResponse();
		}
	},
	/**
	 * Start re-indexing customers for search for a specific company. Happens in the background with Hangfire.
	 * @param {number} companyId company to target
	 */
	async reIndexCustomerSearch(companyId) {
		try {
			return await fetchWrap('/api/Maintenance/ReIndexCustomerSearch/' + companyId, { method: 'POST' });
		} catch {
			return offlineResponse();
		}
	},
};
