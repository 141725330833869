import { url as urlHelper } from '@/helpers/helpers';
import PaginatedList from '@/models/PaginatedList';
import VehicleMaintenanceSchedule from '@/models/VehicleMaintenanceSchedule';
import { fetchAllPages, fetchWrap, offlineResponse, setPagination } from '../_helpers';

export default {
	/**
	 * Get all vehicle maintenance schedules
	 * @param {Object} params request parameters.
	 * @returns (async) Returns an array of VehicleMaintenanceSchedule objects if the request was successful, otherwise a Response.
	 */
	async getAll({ vehicleId = undefined, pastDueWarning = undefined } = {}, maintenanceTypes) {
		const query = {};
		if (typeof vehicleId === 'number') {
			query.vehicleId = vehicleId;
		}
		if (typeof pastDueWarning === 'boolean') {
			query.pastDueWarning = pastDueWarning;
		}
		const url = urlHelper('/api/VehicleMaintenanceSchedule', query);
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(new VehicleMaintenanceSchedule(x, maintenanceTypes)));
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Get paginated vehicle maintenance schedules
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of VehicleMaintenanceSchedule objects if the request was successful, otherwise a Response.
	 */
	async getPaginated({ vehicleId = undefined, pastDueWarning = undefined, limit = undefined, start = undefined } = {}, maintenanceTypes) {
		const query = setPagination(limit, start);
		if (typeof vehicleId === 'number') {
			query.vehicleId = vehicleId;
		}
		if (typeof pastDueWarning === 'boolean') {
			query.pastDueWarning = pastDueWarning;
		}
		const url = urlHelper('/api/VehicleMaintenanceSchedule', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new VehicleMaintenanceSchedule(x, maintenanceTypes));
		} else {
			throw response;
		}
	},
	/**
	 * Get a vehicle maintenance schedule
	 * @param {Number} id VehicleMaintenanceSchedule ID
	 * @returns (async) Returns a VehicleMaintenanceSchedule if the request was successful, otherwise a Response.
	 */
	async getById(id, maintenanceTypes) {
		let response;
		try {
			response = await fetchWrap('/api/VehicleMaintenanceSchedule/' + id);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new VehicleMaintenanceSchedule(await response.json(), maintenanceTypes);
		} else {
			throw response;
		}
	},
	/**
	 * Create a vehicle maintenance schedule
	 * @param {VehicleMaintenanceSchedule} model vehicle maintenance schedule to create.
	 * @returns (async) Returns the new VehicleMaintenanceSchedule if the request was successful, otherwise a Response.
	 */
	async create(model, maintenanceTypes) {
		let response;
		try {
			response = await fetchWrap('/api/VehicleMaintenanceSchedule', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new VehicleMaintenanceSchedule(await response.json(), maintenanceTypes);
		} else {
			return response;
		}
	},
	/**
	 * Update a vehicle maintenance schedule
	 * @param {VehicleMaintenanceSchedule} model vehicle maintenance schedule to update.
	 * @returns (async) Returns the updated VehicleMaintenanceSchedule if the request was successful, otherwise a Response.
	 */
	async update(model, maintenanceTypes) {
		let response;
		try {
			response = await fetchWrap('/api/VehicleMaintenanceSchedule/' + model.id, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new VehicleMaintenanceSchedule(await response.json(), maintenanceTypes);
		} else {
			return response;
		}
	},
	/**
	 * Delete a vehicle maintenance schedule
	 * @param {Number} id VehicleMaintenanceSchedule ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the vehicle maintenance schedule could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/VehicleMaintenanceSchedule/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok || response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
