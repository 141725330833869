import { getValueFromDto as getValue, getDateTimeFromDto as getDate } from './_helpers.js';
import VehicleMaintenanceIntervalType from './VehicleMaintenanceIntervalType.js';
import VehicleMaintenanceType from './VehicleMaintenanceType.js';

export default class VehicleMaintenanceSchedule {
	constructor(dto, maintenanceTypes) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.vehicleId = getValue(dto, 'vehicleId', 'number', null);
		this.vehicle = null;
		Object.defineProperty(this, 'vehicle', { enumerable: false });
		this.maintenanceTypeId = getValue(dto, 'maintenanceTypeId', 'number', null);
		this.maintenanceType = null;
		Object.defineProperty(this, 'maintenanceType', { enumerable: false });
		this.intervalUnits = getValue(dto, 'intervalUnits', 'number', VehicleMaintenanceIntervalType.months);
		this.intervalLength = getValue(dto, 'intervalLength', 'number', 0);
		this.intervalWarningUnits = getValue(dto, 'intervalWarningUnits', 'number', VehicleMaintenanceIntervalType.months);
		this.intervalWarningLength = getValue(dto, 'intervalWarningLength', 'number', 0);
		this.notes = getValue(dto, 'notes', 'string', '');
		// server-side calculated properties
		this.lastTimestamp = getDate(dto, 'lastTimestamp', null);
		Object.defineProperty(this, 'lastTimestamp', { enumerable: false });
		this.lastMileage = getValue(dto, 'lastMileage', 'number', 0);
		Object.defineProperty(this, 'lastMileage', { enumerable: false });
		this.lastHours = getValue(dto, 'lastHours', 'number', 0);
		Object.defineProperty(this, 'lastHours', { enumerable: false });
		this.isPastWarning = getValue(dto, 'isPastWarning', 'boolean', false);
		Object.defineProperty(this, 'isPastWarning', { enumerable: false });
		this.nextTimestamp = getDate(dto, 'nextTimestamp', null);
		Object.defineProperty(this, 'nextTimestamp', { enumerable: false });
		this.nextMileage = getValue(dto, 'nextMileage', 'number', 0);
		Object.defineProperty(this, 'nextMileage', { enumerable: false });
		this.nextHours = getValue(dto, 'nextHours', 'number', 0);
		Object.defineProperty(this, 'nextHours', { enumerable: false });
		this.isPastDue = getValue(dto, 'isPastDue', 'boolean', false);
		Object.defineProperty(this, 'isPastDue', { enumerable: false });

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.maintenanceType === 'object' && dto.maintenanceType !== null) {
				this.maintenanceType = new VehicleMaintenanceType(dto.maintenanceType);
			} else if (typeof maintenanceTypes === 'object' && Array.isArray(maintenanceTypes)) {
				this.maintenanceType = maintenanceTypes.find(x => x.id === this.maintenanceTypeId) || null;
			}
		}
	}
}
