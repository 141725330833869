export default class AppModule {
	static none = 0;
	static rentals = 1;

	static options = [{
		id: this.rentals,
		name: 'Rentals',
		description: 'Rental item management system',
	}];
}
