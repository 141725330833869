import TimeZone from '@/models/TimeZone';

export default {
	getAll() {
		return [
			new TimeZone({ ianaZoneId: 'America/New_York', displayName: 'Eastern Time (EST/EDT)', sortOrder: 1, }),
			new TimeZone({ ianaZoneId: 'America/Chicago', displayName: 'Central Time (CST/CDT)', sortOrder: 2, }),
			new TimeZone({ ianaZoneId: 'America/Denver', displayName: 'Mountain Time (MST/MDT)', sortOrder: 3, }),
			new TimeZone({ ianaZoneId: 'America/Phoenix', displayName: 'Mountain Time (MST)', sortOrder: 4, }),
			new TimeZone({ ianaZoneId: 'America/Los_Angeles', displayName: 'Pacific Time (PST/PDT)', sortOrder: 5, }),
		];
	},
};
