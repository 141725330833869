import account from './modules/account';
import auth from './modules/auth';
import cache from './modules/cache';
import companies from './modules/companies';
import companyLocations from './modules/companyLocations';
import customerPayments from './modules/customerPayments';
import customerRentals from './modules/customerRentals';
import customers from './modules/customers';
import email from './modules/email';
import emails from './modules/emails';
import files from './modules/files';
import inspectionItems from './modules/inspectionItems';
import inventory from './modules/inventory';
import maintenance from './modules/maintenance';
import offline from './modules/offline';
import paymentTerms from './modules/paymentTerms';
import pdf from './modules/pdf';
import permissions from './modules/permissions';
import rentalItemTypes from './modules/rentalItemTypes';
import rentalItems from './modules/rentalItems';
import reports from './modules/reports';
import roles from './modules/roles';
import services from './modules/services';
import settings from './modules/settings';
import states from './modules/states';
import subscriptions from './modules/subscriptions';
import tankTypes from './modules/tankTypes';
import timeClockPunches from './modules/timeClockPunches';
import timeZones from './modules/timeZones';
import token from './modules/token';
import userAbsence from './modules/userAbsence';
import users from './modules/users';
import vehicleMaintenance from './modules/vehicleMaintenance';
import vehicleMaintenanceSchedule from './modules/vehicleMaintenanceSchedule';
import vehicleMaintenanceTypes from './modules/vehicleMaintenanceTypes';
import vehicleTypes from './modules/vehicleTypes';
import vehicles from './modules/vehicles';
import wasteDisposalSiteTypes from './modules/wasteDisposalSiteTypes';
import wasteDisposalSites from './modules/wasteDisposalSites';
import wasteDisposals from './modules/wasteDisposals';
import wasteTypes from './modules/wasteTypes';
import workOrderSchedules from './modules/workOrderSchedules';
import workOrders from './modules/workOrders';

export default {
	account,
	auth,
	cache,
	companies,
	companyLocations,
	customerPayments,
	customerRentals,
	customers,
	email,
	emails,
	files,
	inspectionItems,
	inventory,
	maintenance,
	offline,
	paymentTerms,
	pdf,
	permissions,
	rentalItems,
	rentalItemTypes,
	reports,
	roles,
	services,
	settings,
	states,
	subscriptions,
	tankTypes,
	timeClockPunches,
	timeZones,
	token,
	userAbsence,
	users,
	vehicleMaintenance,
	vehicleMaintenanceSchedule,
	vehicleMaintenanceTypes,
	vehicles,
	vehicleTypes,
	wasteDisposals,
	wasteDisposalSites,
	wasteDisposalSiteTypes,
	wasteTypes,
	workOrders,
	workOrderSchedules,
};
