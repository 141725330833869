import { url as urlHelper } from '@/helpers/helpers';
import { addCompanyIdToUrl } from '../_helpers';

export default {
	getLandDisposalSiteListPdfUrl() {
		return addCompanyIdToUrl('/api/pdf/LandDisposalSiteList').toString()
	},
	getLandDisposalFormPdfUrl() {
		return addCompanyIdToUrl('/api/pdf/LandDisposalForm').toString()
	},
	getEstimatePdfUrl(id) {
		return addCompanyIdToUrl(urlHelper('/api/pdf/Estimate', { id })).toString()
	},
	getWorkOrderPdfUrl(id) {
		return addCompanyIdToUrl(urlHelper('/api/pdf/WorkOrder', { id })).toString()
	},
	getInvoicePdfUrl(id) {
		return addCompanyIdToUrl(urlHelper('/api/pdf/Invoice', { id })).toString()
	},
	getStatementPdfUrl(customerId, startDate, endDate) {
		return addCompanyIdToUrl(urlHelper('/api/pdf/Statement', {
			customerId: customerId,
			startDate: startDate.toISO(),
			endDate: endDate.plus({ days: 1 }).toISO(),
		})).toString()
	},
	getVehicleLogPdfUrl(vehicleId, startDate, endDate) {
		return addCompanyIdToUrl(urlHelper('/api/pdf/VehicleLog', {
			vehicleId: vehicleId,
			startDate: startDate.toISO(),
			endDate: endDate.toISO(),
		})).toString();
	}
};
