import { url as urlHelper } from '@/helpers/helpers';
import TimeClockPunch from '@/models/TimeClockPunch';
import { DateTime } from 'luxon';
import { fetchAllPages, fetchWrap, offlineResponse } from '../_helpers';

export default {
	/**
	 * Get all time clock punches
	 * @param {Object} params request parameters.
	 * @returns (async) Returns an array of TimeClockPunch objects if the request was successful, otherwise a Response.
	 */
	async getAll({ userId = undefined, startDate = undefined, endDate = undefined } = {}) {
		const query = {};
		if (typeof userId === 'number') {
			query.userId = userId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/TimeClockPunches', query);
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(new TimeClockPunch(x)));
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Get all time clock punches
	 * @param {Object} params request parameters.
	 * @returns (async) Returns an array of TimeClockPunch objects if the request was successful, otherwise a Response.
	 */
	async getAllMyPunches({ startDate = undefined, endDate = undefined } = {}) {
		const query = {};
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/TimeClockPunches/My', query);
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(new TimeClockPunch(x)));
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Check if the user can punch
	 * @returns (async) Returns true if the user can punch in or out, otherwise false.
	 */
	async canPunch() {
		let response;
		try {
			response = await fetchWrap('/api/TimeClockPunches/CanPunch');
		} catch {
			return false;
		}
		if (response.ok) {
			return await response.json();
		} else {
			return false;
		}
	},
	/**
	 * Get a time clock punch
	 * @param {Number} id TimeClockPunch ID
	 * @returns (async) Returns a TimeClockPunch if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response;
		try {
			response = await fetchWrap('/api/TimeClockPunches/' + id);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new TimeClockPunch(await response.json());
		} else {
			throw response;
		}
	},
	/**
	 * Get the current user's most recent time clock punch.
	 * @returns (async) Returns a TimeClockPunch if the request was successful, null if 404, otherwise a Response.
	 */
	async getMyLastPunch() {
		let response;
		try {
			response = await fetchWrap('/api/TimeClockPunches/MyLastPunch');
		} catch {
			response = offlineResponse();
		}
		if (response.status === 200) {
			return new TimeClockPunch(await response.json());
		} else if (response.ok || response.status === 404) {
			return null;
		} else {
			throw response;
		}
	},
	/**
	 * Create a time clock punch
	 * @param {TimeClockPunch} model time clock punch to create.
	 * @returns (async) Returns the new TimeClockPunch if the request was successful, otherwise a Response.
	 */
	async create(model) {
		let response;
		try {
			response = await fetchWrap('/api/TimeClockPunches', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new TimeClockPunch(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Create a time clock punch for the current user at the current time.
	 * @param {Boolean} punchIn specify if the user is intending to punch in.
	 * @returns (async) Returns the new TimeClockPunch if the request was successful, otherwise a Response.
	 */
	async punch(punchIn) {
		const url = urlHelper('/api/TimeClockPunches/Punch', { punchIn });
		let response;
		try {
			response = await fetchWrap(url, { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new TimeClockPunch(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Update a time clock punch
	 * @param {TimeClockPunch} model time clock punch to update.
	 * @returns (async) Returns the updated TimeClockPunch if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/TimeClockPunches/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new TimeClockPunch(model);
		} else {
			return response;
		}
	},
	/**
	 * Delete a time clock punch
	 * @param {Number} id TimeClockPunch ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the time clock punch could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/TimeClockPunches/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok || response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
