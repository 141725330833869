import { addCompanyIdToUrl } from '../_helpers';

export default {
	getWasteDisposalsUrl() {return addCompanyIdToUrl('/api/Reports/WasteDisposals').toString();},
	getWasteCollectionsDisposalsUrl() {return addCompanyIdToUrl('/api/Reports/WasteCollectionsDisposals').toString();},
	getVehicleMaintenanceHistoryUrl() {return addCompanyIdToUrl('/api/Reports/VehicleMaintenanceHistory').toString();},
	getPaymentsUrl() {return addCompanyIdToUrl('/api/Reports/Payments').toString();},
	getInvoiceItemReportUrl() {return addCompanyIdToUrl('/api/Reports/InvoiceItemReport').toString();},
	getTimesheetReportUrl() {return addCompanyIdToUrl('/api/Reports/TimesheetReport').toString();},
	getTimesheetByUserReportUrl() {return addCompanyIdToUrl('/api/Reports/TimesheetByUserReport').toString();},
};
