export default class Service {
	constructor(dto) {
		this.id = 0;
		this.name = null;
		this.description = null;
		this.price = 0;
		//this.taxable = null;
		this.wasteTypeId = null;
		Object.defineProperty(this, 'wasteType', { enumerable: false, value: null, writable: true });
		this.requireInspection = false;

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.id === 'number') {
				this.id = dto.id;
			}
			if (typeof dto.name === 'string') {
				this.name = dto.name;
			}
			if (typeof dto.description === 'string') {
				this.description = dto.description;
			}
			if (typeof dto.price === 'number') {
				this.price = dto.price;
			}
			//if (typeof dto.taxable === 'boolean') {
			//	this.taxable = dto.taxable;
			//}
			if (typeof dto.wasteTypeId === 'number') {
				this.wasteTypeId = dto.wasteTypeId;
			}
			if (typeof dto.requireInspection === 'boolean') {
				this.requireInspection = dto.requireInspection;
			}
		}
	}
}
