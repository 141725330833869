import { url as urlHelper } from '@/helpers/helpers';
import { getIdb, idbHelpers } from '@/idb';
import Inventory from '@/models/Inventory';
import PaginatedList from '@/models/PaginatedList';
import { DateTime } from 'luxon';
import { fetchAllPages, fetchWrap, idbResponse, isIdbResponse, offlineResponse, setPagination } from '../_helpers';
import cache from './cache';

const idbStore = 'inventory';

export default {
	/**
	 * Get all inventory
	 * @returns (async) Returns an array of Inventory objects if the request was successful, otherwise a Response.
	 */
	async getAll() {
		const idb = await getIdb();
		let response, data = [], timestamp;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb) {
			try {
				timestamp = DateTime.now();
				response = await fetchAllPages('/api/Inventory', x => data.push(x));
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		if (useIdb && idb) {
			data = await idb.getAll(idbStore);
			response = idbResponse(200);
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await idbHelpers.replaceAll(idb, idbStore, data);
				await cache.setTimestamp(idbStore, timestamp);
			}
			return data.map(x => new Inventory(x));
		} else {
			throw response;
		}
	},
	lowStockFilter(x) {
		return x.lowStockWarning > 0 && (x.quantityOnHand - x.quantityReserved) <= x.lowStockWarning;
	},
	lowStockSort(a, b) {
		return (a.quantityOnHand - a.quantityReserved) - (b.quantityOnHand - b.quantityReserved);
	},
	/**
	 * Get one page of inventory with low stock.
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of Inventory objects if the request was successful, otherwise a Response.
	 */
	async getLowStock({ limit = undefined, start = undefined } = {}) {
		const query = setPagination(limit, start);
		let response, data = null;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb && await cache.canCache()) {
			// cache all for future re-use
			const filteredData = (await this.getAll()).filter(this.lowStockFilter);
			data = {
				start: query.start,
				limit: query.limit,
				totalCount: filteredData.length,
				data: filteredData,
			};
			useIdb = true;
		}
		if (!useIdb) {
			const url = urlHelper('/api/Inventory/LowStock', query);
			try {
				response = await fetchWrap(url);
				if (response.ok) { data = await response.json(); }
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		const idb = await getIdb();
		if (useIdb && idb) {
			if (!data) {
				const filteredData = await idbHelpers.getFiltered(idb, idbStore, this.lowStockFilter);
				data = {
					start: query.start,
					limit: query.limit,
					totalCount: filteredData.length,
					data: filteredData,
				};
			}
			data.data = data.data.sort(this.lowStockSort).slice(query.start, query.start + query.limit);
			response = idbResponse(200);
		}
		if (response.ok) {
			return new PaginatedList(data, x => new Inventory(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a inventory
	 * @param {Number} id Inventory ID
	 * @returns (async) Returns a Inventory if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response, data = null;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb && await cache.canCache()) {
			// cache all for future re-use
			if ((data = (await this.getAll()).find(x => x.id === id) ?? null)) {
				return data;
			} else {
				throw idbResponse(404);
			}
		}
		if (!useIdb) {
			try {
				response = await fetchWrap('/api/Inventory/' + id);
				if (response.ok) { data = await response.json(); }
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		const idb = await getIdb();
		if (useIdb && idb) {
			data = await idb.get(idbStore, id);
			response = data ? idbResponse(200) : idbResponse(404);
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await idb.put(idbStore, data);
			}
			return new Inventory(data);
		} else {
			throw response;
		}
	},
	/**
	 * Create a inventory
	 * @param {Inventory} model inventory to create.
	 * @returns (async) Returns the new Inventory if the request was successful, otherwise a Response.
	 */
	async create(model) {
		let response;
		try {
			response = await fetchWrap('/api/Inventory', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			return new Inventory(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Update a inventory
	 * @param {Inventory} model inventory to update.
	 * @returns (async) Returns the updated Inventory if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/Inventory/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			return new Inventory(model);
		} else {
			return response;
		}
	},
	/**
	 * Delete a inventory
	 * @param {Number} id Inventory ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the inventory could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/Inventory/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			return true;
		} else if (response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
