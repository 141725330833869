import Customer from '@/models/Customer';
import WorkOrder from '@/models/WorkOrder';
import { DateTime } from 'luxon';
import { fetchWrap, offlineResponse } from '../_helpers';
import pdf from './pdf';

function getContactEmailAddresses(contact) {
	return contact.emailAddresses.map(x => ({
		name: contact.fullName + (contact.emailAddresses.length > 1 ? ` (${x.label})` : ''),
		email: x.value
	}));
}

export default {
	/**
	 *
	 * @param {WorkOrder} estimate
	 * @returns
	 */
	buildEstimateEmail(estimate) {
		const billing = estimate.customer.billingContact;

		const email = {
			tos: getContactEmailAddresses(billing),
			// subject: 'Estimate #' + estimate.number + ' from ' + settings.companyName,
			message: '',
			attachments: [{ url: pdf.getEstimatePdfUrl(estimate.id), text: `Estimate-${estimate.number}.pdf` }]
		};

		email.message =
			`To: ${estimate.customer.name}
Attn: ${billing.prefix} ${billing.firstName} ${billing.middleInitial} ${billing.lastName} ${billing.suffix}

Please find billing estimate #${estimate.number} attached.
Please contact us with any questions.`.replaceAll(/[ \t]+/g, ' ').replaceAll(' \n', '\n');

		return email;
	},
	/**
	 *
	 * @param {WorkOrder} invoice
	 * @returns
	 */
	buildInvoiceEmail(invoice) {
		const billing = invoice.customer.billingContact;

		const email = {
			tos: getContactEmailAddresses(billing),
			// subject: 'Invoice #' + invoice.number + ' from ' + settings.companyName,
			message: '',
			attachments: [{ url: pdf.getInvoicePdfUrl(invoice.id), text: `Invoice-${invoice.number}.pdf` }]
		};

		email.message =
			`To: ${invoice.customer.name}
Attn: ${billing.prefix} ${billing.firstName} ${billing.middleInitial} ${billing.lastName} ${billing.suffix}

Please find billing invoice #${invoice.number} from ${invoice.workStartTime.toLocaleString()} attached.
Please contact us with any questions.`.replaceAll(/[ \t]+/g, ' ').replaceAll(' \n', '\n');

		return email;
	},
	/**
	 *
	 * @param {Customer} customer
	 * @param {DateTime} startDate
	 * @param {DateTime} endDate
	 * @returns
	 */
	buildStatementEmail(customer, startDate, endDate) {
		const billing = customer.billingContact;
		const email = {
			tos: getContactEmailAddresses(billing),
			// subject: `Billing statement from ${settings.companyName} for ${startDate.toLocaleString()} - ${endDate.toLocaleString()}`,
			message: '',
			attachments: [{
				url: pdf.getStatementPdfUrl(customer.id, startDate, endDate),
				text: `Statement for ${startDate.toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' })} - ${endDate.toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' })}.pdf`
			}]
		};

		email.message =
			`To: ${customer.name}
Attn: ${billing.prefix} ${billing.firstName} ${billing.middleInitial} ${billing.lastName} ${billing.suffix}

Please find your billing statement for ${startDate.toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })} through ${endDate.toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })} attached.
Please contact us with any questions.`.replaceAll(/[ \t]+/g, ' ').replaceAll(' \n', '\n');

		return email;
	},
	/**
	 * Create a customer estimate PDF to the customer's billing email address.
	 * @param {Number} id Work order ID
	 * @param {string} message The email message
	 * @returns (async) Returns the Response.
	 */
	async sendEstimate(tos, id, message) {
		const model = { tos, id, message };
		let response;
		try {
			response = await fetchWrap('/api/Email/Estimate', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	/**
	 * Create a customer invoice PDF to the customer's billing email address.
	 * @param {Number} id Work order ID
	 * @param {string} message The email message
	 * @returns (async) Returns the Response.
	 */
	async sendInvoice(tos, id, message) {
		const model = { tos, id, message };
		let response;
		try {
			response = await fetchWrap('/api/Email/Invoice', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	/**
	 * Create a new customer statement PDF with the specified parameters and send it to the customer's billing email address.
	 * @param {Number} customerId Customer ID
	 * @param {DateTime} startDate Statement start date
	 * @param {DateTime} endDate Statement end date
	 * @param {string} message The email message
	 * @returns (async) Returns the Response.
	 */
	async sendStatement(tos, customerId, startDate, endDate, message) {
		const model = { tos, customerId, startDate, endDate, message };
		let response;
		try {
			response = await fetchWrap('/api/Email/Statement', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		return response;
	},
	/**
	 * Create a email preview for estimate
	 * @param {string} message The email message
	 * @param {number} id work order id
	 */
	async previewEstimate(id, message) {
		const model = { id, message };
		let response;
		try {
			response = await fetchWrap('/api/Email/Estimate/Preview', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return response.json();
		} else {
			return response;
		}
	},
	/**
	 * Create a email preview for invoice
	 * @param {string} message The email message
	 * @param {number} id work order id
	 */
	async previewInvoice(id, message) {
		const model = { id, message };
		let response;
		try {
			response = await fetchWrap('/api/Email/Invoice/Preview', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return response.json();
		} else {
			return response;
		}
	},
	/**
	 * Create a email preview for statement
	 * @param {string} message The email message
	 * @param {number} customerId customer id
	 * @param {DateTime} startDate start date
	 * @param {DateTime} endDate end date
	 */
	async previewStatement(customerId, startDate, endDate, message) {
		const model = { customerId, startDate, endDate, message };
		let response;
		try {
			response = await fetchWrap('/api/Email/Statement/Preview', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return response.json();
		} else {
			return response;
		}
	}
};
