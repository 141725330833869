export default class VehicleMaintenanceIntervalType {
	static days = 0;
	static weeks = 1;
	static months = 2;
	static miles = 3;
	static hours = 4;

	static options = [
		{ id: this.days, name: 'Day' },
		{ id: this.weeks, name: 'Week' },
		{ id: this.months, name: 'Month' },
		{ id: this.miles, name: 'Mile' },
		{ id: this.hours, name: 'Hour' },
	];

	static display(value, number) {
		const item = this.options.find(x => x.id === value);
		return item ? (item.name + (number === 1 ? '' : 's')) : '';
	}

	static isValid(value) {
		return value === this.days || value === this.weeks || value === this.months || value === this.miles || value === this.hours;
	}
}
