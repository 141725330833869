import { BILLING_TIME_ZONE } from '@/helpers/helpers.js';
import { DateTime } from 'luxon';
import Subscription from './Subscription.js';
import { getArrayFromDto as getArray, getDateTimeFromDto as getDateTime, getObjectFromDto as getObject, getValueFromDto as getValue } from './_helpers.js';

export default class SubscriptionDetails {
	constructor(dto) {
		this.startDate = getDateTime(dto, 'startDate', null);
		if (this.startDate instanceof DateTime && this.startDate.zoneName !== BILLING_TIME_ZONE) { this.startDate = this.startDate.setZone(BILLING_TIME_ZONE)}
		this.endDate = getDateTime(dto, 'endDate', null);
		if (this.endDate instanceof DateTime && this.endDate.zoneName !== BILLING_TIME_ZONE) { this.endDate = this.endDate.setZone(BILLING_TIME_ZONE)}
		this.activeUsers = getValue(dto, 'activeUsers', 'number', 0);
		this.enabledModules = getArray(dto, 'enabledModules');
		this.totalPrice = getValue(dto, 'totalPrice', 'number', 0);
		this.explanation = getArray(dto, 'explanation');
		this.subscription = getObject(dto, 'subscription', Subscription, null);
	}
}
