import { getValueFromDto as getValue } from './_helpers.js';

export default class SubscriptionDetailsReport {
	constructor(dto) {
		this.company = getValue(dto, 'company', 'string', '');
		this.subscription = getValue(dto, 'subscription', 'string', '');
		this.activeUsers = getValue(dto, 'activeUsers', 'number', 0);
		this.totalPrice = getValue(dto, 'totalPrice', 'number', 0);
		this.explanation = getValue(dto, 'explanation', 'string', '');
	}
}
