import { getValueFromDto as getValue, getDateTimeFromDto as getDate } from './_helpers.js';

export default class TimeClockPunch {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.userId = getValue(dto, 'userId', 'number', null);
		this.punchInTime = getDate(dto, 'punchInTime', null);
		this.punchOutTime = getDate(dto, 'punchOutTime', null);
		Object.defineProperty(this, 'isSplitOnPunchInTime', { enumerable: false, value: false, writable: true });
		Object.defineProperty(this, 'isSplitOnPunchOutTime', { enumerable: false, value: false, writable: true });
	}
}
