import { getDateTimeFromDto as getDate, getValueFromDto as getValue } from './_helpers.js';

export default class EmailEvent {
	constructor(dto) {
		// this.emailId = getValue(dto, 'emailId', 'number', 0);
		this.timestamp = getDate(dto, 'timestamp', null);
		this.emailAddress = getValue(dto, 'emailAddress', 'string', '');
		this.eventType = getValue(dto, 'eventType', 'string', '');
		this.attempt = getValue(dto, 'attempt', 'number', 0);
		this.reason = getValue(dto, 'reason', 'string', '');
		this.bounceType = getValue(dto, 'bounceType', 'string', '');
		this.bounceClassification = getValue(dto, 'bounceClassification', 'string', '');
		this.icon = '';
		this.description = '';
		if (this.eventType === 'processed') {
			this.icon = 'status-wait';
			this.description = 'Processed';
		} else if (this.eventType === 'delivered') {
			this.icon = 'status-success';
			this.description = 'Delivered successfully';
		} else if (this.eventType === 'open') {
			this.icon = 'status-success';
			this.description = 'Delivered and opened';
		} else if (this.eventType === 'deferred') {
			this.icon = 'status-warn';
			this.description = `Attempt ${this.attempt} was unsuccessful. Re-attempting.`;
			if (this.reason) {
				this.description += ` Reason: ${this.reason}`
			}
		} else if (this.eventType === 'bounce') {
			this.icon = 'status-error';
			this.description = bounceClassifications[this.bounceClassification] ?? 'Bounced: ' + this.bounceClassification;
			if (this.reason) {
				this.description += ` Reason: ${this.reason}`
			}
		} else if (this.eventType === 'dropped') {
			this.icon = 'status-error';
			this.description = bounceClassifications[this.bounceClassification] ?? 'Dropped: ' + this.bounceClassification;
			if (this.reason) {
				this.description += ` Reason: ${this.reason}`
			}
		}
	}

	get isSuppressed() {
		return this.eventType === 'bounce' || this.eventType === 'dropped';
	}
}

const bounceClassifications = {
	'Invalid Address': 'Invalid address, not delivered',
	'Technical Failure': 'Technical failure, not delivered',
	'Content': 'Content issue, not delivered',
	'Reputation': 'Not delivered due to bad email reputation',
	'Frequency or Volume Too High': 'Not delivered due to high email frequency or volume',
	'Mailbox Unavailable': 'Not delivered due to an unavailable mailbox',
	'Unclassified': 'Not delivered due to unknown issue',
}
