import { toDictionary } from '@/helpers/helpers.js';
import EmailEvent from './EmailEvent.js';
import { getArrayOfObjectsFromDto, getDateTimeFromDto as getDate, getValueFromDto as getValue } from './_helpers.js';

function parseAddressLineToAddresses(addressLine, eventLookup) {
	if (!addressLine) { return []; };
	return addressLine.split(';').map(x => {
		const split = x.indexOf('<');
		const end = x.indexOf('>');
		const y = { name: '', email: '', event: null };
		if (split >= 0 && end > split) {
			y.name = x.substring(0, split).trim();
			y.email = x.substring(split + 1, end).trim();
		} else {
			y.email = x;
		}
		y.event = eventLookup[y.email.toLowerCase()] ?? null;
		return y;
	});
}

export default class Email {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.timeQueued = getDate(dto, 'timeQueued', null);
		this.timeSent = getDate(dto, 'timeSent', null);
		this.status = getValue(dto, 'status', 'number', 0);
		this.fromLine = getValue(dto, 'fromLine', 'string', '');
		this.replyToLine = getValue(dto, 'replyToLine', 'string', '');
		this.toLine = getValue(dto, 'toLine', 'string', '');
		this.ccLine = getValue(dto, 'ccLine', 'string', '');
		this.bccLine = getValue(dto, 'bccLine', 'string', '');
		this.subject = getValue(dto, 'subject', 'string', '');
		this.events = getArrayOfObjectsFromDto(dto, 'events', EmailEvent);
		const eventLookup = toDictionary(this.events, x => x.emailAddress);
		this.tos = parseAddressLineToAddresses(this.toLine, eventLookup);
		this.ccs = parseAddressLineToAddresses(this.ccLine, eventLookup);
		this.bccs = parseAddressLineToAddresses(this.bccLine, eventLookup);
	}
}
