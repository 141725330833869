import { getArrayFromDto as getArray, getValueFromDto as getValue } from './_helpers.js';

export default class UserSelf {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.emailAddress = getValue(dto, 'emailAddress', 'string', '');
		this.emailConfirmed = getValue(dto, 'emailConfirmed', 'boolean', false);
		this.roles = getArray(dto, 'roles', []);
		this.permissions = getArray(dto, 'permissions', []);
		this.modules = getArray(dto, 'modules', []);
		this.firstName = getValue(dto, 'firstName', 'string', '');
		this.middleInitial = getValue(dto, 'middleInitial', 'string', '');
		this.lastName = getValue(dto, 'lastName', 'string', '');
		this.phoneNumber = getValue(dto, 'phoneNumber', 'string', '');
		this.operator = getValue(dto, 'operator', 'boolean', false);
		this.isPunchedIn = getValue(dto, 'isPunchedIn', 'boolean', false);
		this.companyId = getValue(dto, 'companyId', 'number', null);
		this.isAdmin = getValue(dto, 'isAdmin', 'boolean', false);
		this.isImpersonating = getValue(dto, 'isImpersonating', 'boolean', false);
		this.isInternal = getValue(dto, 'isInternal', 'boolean', false);
		this.canSwitchCompanies = getValue(dto, 'canSwitchCompanies', 'boolean', false);
	}

	get name() {
		return (this.firstName + ' ' + this.lastName).trim();
	}

	get initials() {
		return (this.firstName ? this.firstName.substr(0, 1) : '') + (this.middleInitial || '') + (this.lastName ? this.lastName.substr(0, 1) : '');
	}

	toString() {
		return `${this.firstName} ${this.lastName} (${this.initials})`;
	}
}
