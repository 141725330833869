import { url as urlHelper } from '@/helpers/helpers';
import PaginatedList from '@/models/PaginatedList';
import WorkOrderSchedule from '@/models/WorkOrderSchedule';
import { compare as jsonPatchCompare } from 'fast-json-patch';
import { DateTime } from 'luxon';
import { fetchAllPages, fetchWrap, offlineResponse, setPagination } from '../_helpers';

export default {
	/**
	 * Get all work order schedules
	 * @param {Object} params request parameters.
	 * @returns (async) Returns an array of WorkOrderSchedule objects if the request was successful, otherwise a Response.
	 */
	async getAll({ customerId = undefined, customerLocationId = undefined, startDate = undefined, endDate = undefined } = {}) {
		const query = {};
		if (typeof customerId === 'number') {
			query.customerId = customerId;
		}
		if (typeof customerLocationId === 'number') {
			query.customerLocationId = customerLocationId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		const url = urlHelper('/api/WorkOrderSchedules', query);
		let response, data = [];
		try {
			response = await fetchAllPages(url, x => data.push(new WorkOrderSchedule(x)));
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return data;
		} else {
			throw response;
		}
	},
	/**
	 * Get paginated work order schedules
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of WorkOrderSchedule objects if the request was successful, otherwise a Response.
	 */
	async getPaginated({ customerId = undefined, customerLocationId = undefined, active = undefined, startDate = undefined, endDate = undefined, limit = undefined, start = undefined } = {}) {
		const query = setPagination(limit, start);
		if (typeof customerId === 'number') {
			query.customerId = customerId;
		}
		if (typeof customerLocationId === 'number') {
			query.customerLocationId = customerLocationId;
		}
		if (startDate instanceof DateTime) {
			query.startDate = startDate.toISO();
		}
		if (endDate instanceof DateTime) {
			query.endDate = endDate.toISO();
		}
		if(typeof active === 'boolean') {
			query.active = active;
		}
		const url = urlHelper('/api/WorkOrderSchedules', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new WorkOrderSchedule(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get paginated work order schedules which have an error
	 * @param {Object} params request parameters.
	 * @returns (async) Returns a PaginatedList of WorkOrderSchedule objects if the request was successful, otherwise throws a Response.
	 */
	async getPaginatedWithErrors({ limit = undefined, start = undefined } = {}) {
		const query = setPagination(limit, start);
		const url = urlHelper('/api/WorkOrderSchedules/WithErrors', query);
		let response;
		try {
			response = await fetchWrap(url);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new PaginatedList(await response.json(), x => new WorkOrderSchedule(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a work order schedule
	 * @param {Number} id WorkOrderSchedule ID
	 * @returns (async) Returns a WorkOrderSchedule if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response;
		try {
			response = await fetchWrap('/api/WorkOrderSchedules/' + id);
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return new WorkOrderSchedule(await response.json());
		} else {
			throw response;
		}
	},
	/**
	 * Update a work order schedule
	 * @param {WorkOrderSchedule} model work order schedule to update.
	 * @param {WorkOrderSchedule} oldModel old work order schedule to determine updates to apply.
	 * @returns (async) Returns the updated WorkOrderSchedule if the request was successful, otherwise a Response.
	 */
	async patch(model, oldModel) {
		const oldData = JSON.parse(JSON.stringify(oldModel));
		const newData = JSON.parse(JSON.stringify(model));
		const patch = jsonPatchCompare(oldData, newData);
		if (patch.length === 0) {
			return model instanceof WorkOrderSchedule ? model : new WorkOrderSchedule(model);
		}
		let response;
		try {
			response = await fetchWrap('/api/WorkOrderSchedules/' + model.id, {
				method: 'PATCH',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(patch),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			const data = await response.json();
			return new WorkOrderSchedule(data);
		} else {
			return response;
		}
	},
	/**
	 * Delete a work order schedule
	 * @param {Number} id WorkOrderSchedule ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the work order schedule could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/WorkOrderSchedules/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok || response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
