import AppModule from '@/models/AppModule';
import Permission from '@/models/Permission';
import PermissionDisplay from '@/models/PermissionDisplay';

const adminPermissions = [
	new PermissionDisplay({
		id: Permission.adminAccessAll,
		key: 'adminAccessAll',
		group: 'Admin',
		name: 'Super Admin',
		description: 'This allows the user to access every feature',
		sortOrder: 1,
	}),
	new PermissionDisplay({
		id: Permission.admin,
		key: 'admin',
		group: 'Admin',
		name: 'Admin',
		description: 'Access Administration Area',
		sortOrder: 2,
	}),
	new PermissionDisplay({
		id: Permission.adminUsers,
		key: 'adminUsers',
		group: 'Admin',
		name: 'Manage Admin Users',
		description: 'Manage all admin users',
		sortOrder: 3,
	}),
	new PermissionDisplay({
		id: Permission.adminRoles,
		key: 'adminRoles',
		group: 'Admin',
		name: 'Manage Admin Roles',
		description: 'Manage all admin roles and permissions',
		sortOrder: 4,
	}),
	new PermissionDisplay({
		id: Permission.adminHangfire,
		key: 'adminHangfire',
		group: 'Admin',
		name: 'Hangfire',
		description: 'View Hangfire dashboard',
		sortOrder: 5,
	}),
	new PermissionDisplay({
		id: Permission.adminImpersonate,
		key: 'adminImpersonate',
		group: 'Admin',
		name: 'Company Impersonation',
		description: 'Temporarily join a company using your own permissions',
		sortOrder: 6,
	}),
];

const adminPermissionIds = new Set([
	Permission.adminAccessAll,
	Permission.admin,
	Permission.adminUsers,
	Permission.adminRoles,
	Permission.adminHangfire,
	Permission.adminImpersonate,
]);

const companyPermissions = [
	new PermissionDisplay({
		id: Permission.companyAccessAll,
		key: 'companyAccessAll',
		group: 'Company',
		name: 'All Access',
		description: 'This allows the user to access every feature',
		sortOrder: 11,
	}),
	new PermissionDisplay({
		id: Permission.companyOperator,
		key: 'companyOperator',
		group: 'Company',
		name: 'Operator Interface',
		description: '',
		sortOrder: 12,
	}),
	new PermissionDisplay({
		id: Permission.companyOffice,
		key: 'companyOffice',
		group: 'Company',
		name: 'Office Interface',
		description: '',
		sortOrder: 13,
	}),
	new PermissionDisplay({
		id: Permission.companySettings,
		key: 'companySettings',
		group: 'Company',
		name: 'Portal Settings',
		description: 'Including company name, payment settings and terms, selection lists, etc.',
		sortOrder: 14,
	}),
	new PermissionDisplay({
		id: Permission.companyUsers,
		key: 'companyUsers',
		group: 'Company',
		name: 'Manage Employees',
		description: 'Including absences, wages, education credits, etc.',
		sortOrder: 15,
	}),
	new PermissionDisplay({
		id: Permission.companyTimeSheets,
		key: 'companyTimeSheets',
		group: 'Company',
		name: 'Manage Timesheets',
		description: 'See and manage all employee\'s timesheets.',
		sortOrder: 16,
	}),
	new PermissionDisplay({
		id: Permission.companyReports,
		key: 'companyReports',
		group: 'Company',
		name: 'Reports',
		description: '',
		sortOrder: 17,
	}),
	new PermissionDisplay({
		id: Permission.companyBypassIpRestrictions,
		key: 'companyBypassIpRestrictions',
		group: 'Company',
		name: 'Bypass Timeclock Restrictions',
		description: 'This allows the user to punch in/out on any network.',
		sortOrder: 18,
	}),
	new PermissionDisplay({
		id: Permission.companyRentals,
		key: 'companyRentals',
		group: 'Company',
		name: 'Rentals',
		description: 'This allows the user to manage customer rentals and rental items.',
		sortOrder: 18,
		module: AppModule.rentals
	}),
];

const companyPermissionIds = new Set([
	Permission.companyAccessAll,
	Permission.companyOperator,
	Permission.companyOffice,
	Permission.companySettings,
	Permission.companyUsers,
	Permission.companyTimeSheets,
	Permission.companyReports,
	Permission.companyBypassIpRestrictions,
	Permission.companyRentals,
]);

export default {
	isAdminPermission(permission) {
		return permission === Permission.adminAccessAll ||
			permission === Permission.admin ||
			permission === Permission.adminUsers ||
			permission === Permission.adminRoles ||
			permission === Permission.adminHangfire ||
			permission === Permission.adminImpersonate
	},
	togglePermission(array, isAdmin, id) {
		const allowed = isAdmin ? adminPermissionIds : companyPermissionIds;
		const index = array.indexOf(id);
		const toRemove = new Set();
		const toAdd = new Set();
		if (index >= 0) {
			// remove permission
			toRemove.add(id);
			if (id !== Permission.adminAccessAll) {
				// make sure all access is not checked
				toRemove.add(Permission.adminAccessAll);
			}
			if (id !== Permission.companyAccessAll) {
				// make sure all access is not checked
				toRemove.add(Permission.companyAccessAll);
			}
			if (id === Permission.admin) {
				// admin permissions
				toRemove.add(Permission.adminUsers);
				toRemove.add(Permission.adminRoles);
				toRemove.add(Permission.adminHangfire);
				toRemove.add(Permission.adminImpersonate);
			}
			if (id === Permission.companyOffice) {
				// office permissions
				toRemove.add(Permission.companySettings);
				toRemove.add(Permission.companyUsers);
				toRemove.add(Permission.companyTimeSheets);
				toRemove.add(Permission.companyReports);
			}
		} else {
			// add permission
			toAdd.add(id);
			if (id === Permission.adminAccessAll) {
				// check all
				for (const x of adminPermissionIds) {
					toAdd.add(x);
				}
			} else if (id === Permission.companyAccessAll) {
				// check all
				for (const x of companyPermissionIds) {
					toAdd.add(x);
				}
			} else if (id === Permission.adminUsers || id === Permission.adminRoles || id === Permission.adminHangfire || id === Permission.adminImpersonate) {
				// admin permissions
				toAdd.add(Permission.admin);
			} else if (id === Permission.companySettings || id === Permission.companyUsers || id === Permission.companyTimeSheets || id === Permission.companyReports || id === Permission.companyRentals) {
				// office permissions
				toAdd.add(Permission.companyOffice);
			}
		}
		// add new permissions
		for (const x of toAdd) {
			if (allowed.has(x) && !toRemove.has(x) && !array.includes(x)) {
				array.push(x);
			}
		}
		// remove permissions
		for (let i = 0; i < array.length; i++) {
			const x = array[i];
			if (toRemove.has(x) || !allowed.has(x)) {
				array.splice(i, 1);
				i--;
			}
		}
	},
	getAllForAdmin() {
		return [...adminPermissions];
	},
	getAllForCompany(modules) {
		return companyPermissions.filter(x => !x.module || modules.includes(x.module));
	},
};
